/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'go_to_flight_arrow_hover': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<defs><filter x="-15.3%" y="-9.7%" width="125%" height="125%" filterUnits="objectBoundingBox" id="svgicon_go_to_flight_arrow_hover_a"><feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/><feOffset dx="-1" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0.505882353 0 0 0 0 0.505882353 0 0 0 0 0.505882353 0 0 0 0.2 0" in="shadowBlurOuter1"/></filter><circle pid="0" id="svgicon_go_to_flight_arrow_hover_b" cx="18" cy="18" r="18"/></defs><g _fill="none" fill-rule="evenodd"><g transform="rotate(-90 20 18)"><use _fill="#000" filter="url(#svgicon_go_to_flight_arrow_hover_a)" xlink:href="#svgicon_go_to_flight_arrow_hover_b"/><use _stroke="#154f4a" stroke-width="2" _fill="#F4F4F6" xlink:href="#svgicon_go_to_flight_arrow_hover_b"/></g><path pid="1" d="M18.293 23.536a1 1 0 001.414 1.414l4.066-4.066a1.25 1.25 0 000-1.768l-4.066-4.066a1 1 0 00-1.414 1.414L21.828 20l-3.535 3.536z" _fill="#154f4a" fill-rule="nonzero"/></g>'
  }
})
